<template>
  <div class="container-fluid" :class="{ loading: loading }">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>
              {{ `${$t("COMMON.SPOTS_MAP")}` }}
            </h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>
                  {{ `${$t("COMMON.SPOTS_MAP")}` }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right"></div>
      </div>
      <spot-map-selector />
    </div>
    <div v-else class="page-wrapper orga-select">
      <img src="/img/kw-fire.svg" alt="icon" />
      <p>{{ $t("COMMON.PLEASE_SELECT_ORGANIZATION") }}</p>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Button, Option, Select } from "element-ui";
import { mapGetters } from "vuex";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  OPTIONS_SPOTS_MAP_IMAGE_URL,
  OPTIONS_SPOTS_MAP_IMAGE_DATA,
} from "@/constants/options";
import SpotMapSelector from "../../../../components/SpotMapSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    SpotMapSelector,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),

    imageStyle() {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      return {
        transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
        width: `${this.imageWidth}px`,
        height: `${this.imageHeight}px`,
      };
    },
  },

  watch: {
    organizationConfig: async function () {
      await this.loadMapData();
      await this.loadImage();
    },
  },

  data() {
    return {
      imageSrc: "",
      imageWidth: 0,
      imageHeight: 0,
      zoomOptions: {
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        startX: 0,
        startY: 0,
        isDragging: false,
        hasDragged: false,
      },
      currentSpot: {
        x: 0,
        y: 0,
        isDragging: false,
        hasDragged: false,
      },
      spotDragShadow: {
        x: 0,
        y: 0,
      },
      spotHelper: {
        x: 0,
        y: 0,
      },
      mapData: {
        pointSize: 15,
        defaultZoom: 1,
      },
      hotspots: [],
      spots: {},
      loading: false,
      showMapForm: false,
      showHelper: false,
      showCodes: false,
      dragStartsAt: 0,
      selectedSpot: null,
    };
  },

  async mounted() {
    await this.getSpotsList();
    await this.loadMapData();
    await this.loadImage();
  },

  methods: {
    loadMapData() {
      const fetchedMapData =
        this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_DATA];
      const mapData = {
        pointSize: parseInt(fetchedMapData.pointSize || 15),
      };
      this.mapData = mapData;
    },

    fetchImage(url) {
      this.loading = true;
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const calculateDimensions = (width, height, maxDimension) => {
            let adjustedHeight = width;
            let adjustedWidth = height;
            if (width > height) {
              adjustedWidth = Math.ceil(width * (maxDimension / width));
              adjustedHeight = Math.ceil((adjustedWidth * height) / width);
            } else {
              adjustedHeight = Math.ceil(height * (maxDimension / height));
              adjustedWidth = Math.ceil((adjustedHeight * width) / height);
            }
            return { adjustedWidth, adjustedHeight };
          };

          const container = this.$refs.mapContainer;
          const containerWidth = container.offsetWidth;
          const containerHeight = container.offsetHeight;

          const { adjustedWidth, adjustedHeight } = calculateDimensions(
            img.width,
            img.height,
            1000
          );
          this.imageWidth = adjustedWidth;
          this.imageHeight = adjustedHeight;

          const defaultOffsetX =
            (containerWidth - adjustedWidth * this.zoomOptions.scale) / 2;
          const defaultOffsetY =
            (containerHeight - adjustedHeight * this.zoomOptions.scale) / 2;
          let scale =
            Math.min(containerWidth, containerHeight) /
            Math.min(adjustedWidth, adjustedHeight);

          const zoomOptions = cloneDeep(this.zoomOptions);
          zoomOptions.offsetX = defaultOffsetX;
          zoomOptions.offsetY = defaultOffsetY;
          zoomOptions.scale = scale;
          this.zoomOptions = zoomOptions;

          this.imageSrc = img.src;
          this.loading = false;
          resolve(img);
        };
        img.onerror = () => {
          reject(new Error("Could not load image at " + imageUrl));
        };
        img.src = url;
      });
    },

    async loadImage() {
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      await this.fetchImage(imageUrl);
      this.zoomOut();
    },

    async mapImageChanged(file_url) {
      this.loading = true;
      await this.$store.dispatch("organizationOptions/updateOptionByKey", {
        key: OPTIONS_SPOTS_MAP_IMAGE_URL,
        value: file_url,
        organizationId: this.organizationConfig.id,
      });
      this.loading = false;
    },

    async getSpotsList() {
      try {
        this.loading = true;
        let params = {
          page: {
            number: 1,
            size: 999999,
          },
          include: "spotType,category",
        };
        await this.$store.dispatch("spots/list", params);
        const spotsList = this.$store.getters["spots/list"];

        this.hotspots = spotsList.map((item) => {
          return {
            x: item.map_position_x,
            y: item.map_position_y,
            spotId: item.id,
            code: item.code,
          };
        });
        const spotsMapIdSpot = {};
        for (const item of spotsList) {
          spotsMapIdSpot[item.id.toString()] = item;
        }
        this.spots = spotsMapIdSpot;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async updateSpotMapPosition(spotId, x, y) {
      if (!spotId) {
        return;
      }
      try {
        this.loading = true;
        const spotData = cloneDeep(this.spots[spotId]);
        spotData.map_position_x = x;
        spotData.map_position_y = y;
        await this.$store.dispatch("spots/update", spotData);
        await this.getSpotsList();
        this.closeMapForm();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteSpotPosition(spotId) {
      await this.updateSpotMapPosition(spotId, 0, 0);
      this.closeMapForm();
    },

    async updateMapData() {
      this.loading = true;
      const mapData = cloneDeep(this.mapData);
      await this.$store.dispatch("organizationOptions/updateOptionByKey", {
        key: OPTIONS_SPOTS_MAP_IMAGE_DATA,
        value: mapData,
        organizationId: this.organizationConfig.id,
      });
      this.loading = false;
    },

    getSpotCoords(event) {
      const { scale } = this.zoomOptions;
      const rect = this.$refs.imageContainer.getBoundingClientRect();
      const x = (event.clientX - rect.x) / scale;
      const y = (event.clientY - rect.y) / scale;
      return { x, y };
    },

    getHotspotStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = hotspot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${this.mapData.pointSize}px`,
          height: `${this.mapData.pointSize}px`,
        };
      }

      const markerWith = this.mapData.pointSize * scale;
      const markerHeight = this.mapData.pointSize * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale +
        offsetWidth / 2 +
        offsetX -
        markerWith / 2;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight / 2;

      return {
        position: "absolute",
        left: `${newLeft}px`,
        top: `${newTop}px`,
        width: `${markerWith}px`,
        height: `${markerHeight}px`,
      };
    },

    getMapFormStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = this.currentSpot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${this.mapData.pointSize}px`,
          height: `${this.mapData.pointSize}px`,
        };
      }

      const markerHeight = this.mapData.pointSize * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale + offsetWidth / 2 + offsetX - 150;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight -
        186 -
        5;

      return {
        left: `${newLeft}px`,
        top: `${newTop}px`,
      };
    },

    zoom(event) {
      event.preventDefault();
      const zoomSpeed = 0.1;
      const scaleFactor = event.deltaY > 0 ? -zoomSpeed : zoomSpeed;
      this.zoomOptions.scale = Math.min(
        Math.max(this.zoomOptions.scale + scaleFactor, 0.5),
        5
      );
    },

    zoomIn() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.min(this.zoomOptions.scale + zoomSpeed, 5);
    },

    zoomOut() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.max(
        this.zoomOptions.scale - zoomSpeed,
        0.5
      );
    },

    addHotspot(event) {
      this.stopDraggingHotspot();
      this.stopDraggingImage();
      this.currentSpot = this.getSpotCoords(event);
      this.showMapForm = true;
    },

    openHotspotForm(hotspot) {
      this.currentSpot = hotspot;
      this.currentSpot.hasDragged = false;
      this.currentSpot.isDragging = false;
      this.showMapForm = true;
    },

    closeMapForm() {
      this.stopDraggingHotspot();
      this.stopDraggingImage();
      this.currentSpot = { x: 0, y: 0 };
      this.showMapForm = false;
    },

    onMouseDownImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.showMapForm) {
        if (this.$refs.mapFormContainer.contains(event.target)) {
          return;
        }
        this.closeMapForm();
        return;
      }
      if (this.$refs.zoomControlsContainer.contains(event.target)) {
        return;
      }

      this.dragStartsAt = Date.now();
      this.startDragImage(event);
    },

    onMouseMoveImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.zoomOptions.isDragging) {
        this.dragImage(event);
        return;
      }
      if (this.currentSpot.isDragging) {
        this.dragHotspot(event);
        return;
      }
      if (this.showHelper) {
        this.moveSpotHelper(event);
        return;
      }
    },

    onMouseUpImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.showMapForm) {
        if (this.$refs.mapFormContainer.contains(event.target)) {
          return;
        }
      }
      if (this.$refs.zoomControlsContainer.contains(event.target)) {
        return;
      }

      if (Date.now() - this.dragStartsAt < 100) {
        if (this.currentSpot.isDragging) {
          this.openHotspotForm(this.currentSpot);
        } else {
          this.addHotspot(event);
        }
        this.zoomOptions.isDragging = false;
        this.currentSpot.isDragging = false;
        return;
      }
      if (this.zoomOptions.hasDragged) {
        this.zoomOptions.isDragging = false;
        this.stopDraggingImage(event);
        return;
      }
      if (this.currentSpot.hasDragged) {
        this.currentSpot.isDragging = false;
        this.stopDraggingHotspot(event);
        this.updateSpotMapPosition(
          this.currentSpot.spotId,
          this.spotDragShadow.x,
          this.spotDragShadow.y
        );
        return;
      }
    },

    onMouseLeaveImage(event) {
      event.preventDefault();
      event.stopPropagation();

      this.stopDraggingImage();
      this.stopDraggingHotspot();
    },

    onMouseDownHotspot(event, hotspot) {
      event.preventDefault();
      event.stopPropagation();
      this.dragStartsAt = Date.now();
      this.startDraggingHotspot(event, hotspot);
    },

    startDragImage(event) {
      this.zoomOptions.isDragging = true;
      this.zoomOptions.startX = event.clientX - this.zoomOptions.offsetX;
      this.zoomOptions.startY = event.clientY - this.zoomOptions.offsetY;
    },

    dragImage(event) {
      this.zoomOptions.hasDragged = true;
      this.zoomOptions.offsetX = event.clientX - this.zoomOptions.startX;
      this.zoomOptions.offsetY = event.clientY - this.zoomOptions.startY;
      return;
    },

    stopDraggingImage(event) {
      this.zoomOptions.isDragging = false;
      this.zoomOptions.hasDragged = false;
      return;
    },

    startDraggingHotspot(event, hotspot) {
      this.currentSpot = hotspot;
      this.currentSpot.isDragging = true;
    },

    dragHotspot(event) {
      this.currentSpot.hasDragged = true;
      this.spotDragShadow = this.getSpotCoords(event);
    },

    stopDraggingHotspot(event) {
      this.currentSpot.isDragging = false;
      this.currentSpot.hasDragged = false;
    },

    moveSpotHelper(event) {
      this.spotHelper = this.getSpotCoords(event);
    },
  },
};
</script>