<template>
  <div class="map-management">
    <div class="map-management-header" v-if="editMapMode">
      <div class="map-management-header-left">
        <p>
          {{ $t("COMMON.ADD_LOCATIONS_POINTS") }}
          <i v-if="loading" class="fas fa-spinner fa-spin"></i>
        </p>
        <base-input
          v-if="!filteredSpot"
          :placeholder="`${$t('SPOTS.MAP_POINT_SIZE')}`"
        >
          <spot-selector
            @spotChanged="
              (spotId) => {
                selectedSpot = spotId;
              }
            "
          />
        </base-input>
        <base-input
          :placeholder="`${$t('SPOTS.MAP_POINT_SIZE')}`"
          v-model="mapData.pointSize"
          @change="updateMapData"
          type="number"
        ></base-input>
        <base-input>
          <base-checkbox v-model="showHelper">
            {{ $t("SPOTS.SHOW_HELPER") }}
          </base-checkbox>
        </base-input>
        <base-input>
          <base-checkbox v-model="showCodes">
            {{ $t("SPOTS.SHOW_CODES") }}
          </base-checkbox>
        </base-input>
      </div>
      <div class="map-management-header-right">
        <image-selector
          :ressource_id="globalOrganization ? globalOrganization.id : 0"
          ressource_name="options"
          field="map_picture"
          @imageChanged="mapImageChanged"
          :label="`${$t('COMMON.ADD_MAP_IMAGE')}`"
          inline
        />
      </div>
    </div>
    <div class="map-management-content">
      <div
        ref="mapContainer"
        class="image-container"
        :class="{
          dragging: zoomOptions.isDragging,
          draggingSpot: currentSpot.isDragging,
          loading: loading,
        }"
        @mousedown.prevent="onMouseDownImage"
        @mousemove.prevent="onMouseMoveImage"
        @mouseup.prevent="onMouseUpImage"
        @mouseleave.prevent="onMouseLeaveImage"
        @wheel.prevent="zoom"
        @contextmenu.prevent="() => {}"
      >
        <img
          id="map-image"
          ref="imageContainer"
          :src="imageSrc"
          :style="imageStyle"
        />

        <div
          ref="mapFormContainer"
          v-if="showMapForm"
          class="map-form"
          :style="getMapFormStyle()"
        >
          <div v-if="!filteredSpot" class="map-form-header">
            <p>{{ $t("COMMON.ADD_LOCATION") }}</p>
            <button @click="closeMapForm" class="close">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="map-form-content">
            <el-select
              v-if="!filteredSpot"
              v-model="currentSpot.spotId"
              :filterable="true"
              :placeholder="`${$t('COMMON.SEARCH_LOCATION')}`"
              @change="
                (value) => {
                  updateSpotMapPosition(value, currentSpot.x, currentSpot.y);
                }
              "
            >
              <el-option
                v-for="spot in spots"
                :key="spot.id"
                :value="spot.id"
                :label="`#${spot.code} - ${spot.name}`"
                :class="{
                  'spot-used':
                    spot.map_position_x != 0 && spot.map_position_y != 0,
                }"
              >
              </el-option>
            </el-select>
            <div class="location">
              <span>{{ $t("COMMON.SPOT") }}</span>
              <div class="location-selected" v-if="!filteredSpot">
                <div class="located" v-if="spots[currentSpot.spotId]?.id">
                  <div class="located-image">
                    <img
                      :src="
                        spots[currentSpot.spotId].category.icon
                          ? spots[currentSpot.spotId].category.icon
                          : '/img/default-category-icon.svg'
                      "
                      style="max-height: 35px; display: inline"
                      alt="icon"
                    />
                  </div>
                  <div class="located-text">
                    <span>{{ spots[currentSpot.spotId].code }}</span>
                    <span>
                      {{ spots[currentSpot.spotId].spotType.name }}
                    </span>
                  </div>
                  <div class="located-delete">
                    <button
                      @click="deleteSpotPosition(currentSpot.spotId)"
                      class="delete"
                    >
                      <img src="/img/e-trash.svg" alt="icon" />
                    </button>
                  </div>
                </div>
                <p v-else>{{ $t("COMMON.NONE") }}</p>
              </div>
              <div class="location-selected" v-if="!!filteredSpot">
                <div class="located" v-if="spots[currentSpot.spotId]?.id">
                  <div class="located-image">
                    <img
                      :src="
                        spots[currentSpot.spotId].category.icon
                          ? spots[currentSpot.spotId].category.icon
                          : '/img/default-category-icon.svg'
                      "
                      style="max-height: 35px; display: inline"
                      alt="icon"
                    />
                  </div>
                  <div class="located-text">
                    <span>{{ spots[currentSpot.spotId].code }}</span>
                    <span>
                      {{ spots[currentSpot.spotId].spotType.name }}
                    </span>
                  </div>
                  <div class="located-view">
                    <button @click="viewSpot(spots[currentSpot.spotId])">
                      <img src="/img/expand.svg" alt="icon" />
                    </button>
                  </div>
                </div>
                <button
                  v-else
                  @click="setFilteredSpotPosition(currentSpot)"
                  type="button"
                >
                  {{ $t("SPOTS.SET_SPOT_POSITION") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="currentSpot.x != 0 && currentSpot.y != 0"
          class="hotspot-current"
          :style="getHotspotStyle(currentSpot)"
        >
          <span class="hotspot-marker"></span>
        </div>

        <div
          v-if="
            spotDragShadow.x != 0 &&
            spotDragShadow.y != 0 &&
            currentSpot.isDragging
          "
          class="hotspot-shadow"
          :style="getHotspotStyle(spotDragShadow)"
        >
          <span class="hotspot-marker"></span>
        </div>

        <div
          v-if="showHelper && !showMapForm"
          class="hotspot-helper"
          :style="getHotspotStyle(spotHelper)"
          @click="addHotspot"
        >
          <span class="hotspot-marker"></span>
        </div>

        <div
          v-for="(hotspot, index) in hotspots.filter(
            (item) => item.x != 0 && item.y != 0
          )"
          :key="index"
          :style="getHotspotStyle(hotspot)"
          :title="hotspot.code"
          :class="{
            searched:
              hotspot.spotId == selectedSpot ||
              hotspot.spotId == filteredSpot?.id,
          }"
          @mousedown.prevent="(event) => onMouseDownHotspot(event, hotspot)"
          class="hotspot"
        >
          <span class="hotspot-marker"></span>
          <span
            class="hotspot-code"
            v-if="showCodes"
            @mousedown.prevent="(event) => onMouseDownHotspot(event, hotspot)"
          >
            {{ hotspot.code }}
          </span>
        </div>

        <div class="zoom-controls" ref="zoomControlsContainer">
          <button @click.stop="zoomIn">
            <i class="far fa-plus"></i>
          </button>
          <button @click.stop="zoomOut">
            <i class="far fa-minus"></i>
          </button>
        </div>
      </div>

      <div v-if="!organizationConfig.SPOTS_MAP_IMAGE_URL" class="no-image">
        <img src="/img/map-placeholder.svg" alt="map" />
        <p>{{ $t("COMMON.NO_IMAGE_ADDED") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Button, Option, Select } from "element-ui";
import { mapGetters } from "vuex";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ImageSelector from "@/components/ImageSelector.vue";
import {
  OPTIONS_SPOTS_MAP_IMAGE_URL,
  OPTIONS_SPOTS_MAP_IMAGE_DATA,
} from "@/constants/options";
import SpotSelector from "@/components/SpotSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ImageSelector,
    SpotSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    filteredSpot: {
      type: Object,
      required: false,
      default: null,
    },
    editMapMode: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),

    imageStyle() {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      return {
        transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
        width: `${this.imageWidth}px`,
        height: `${this.imageHeight}px`,
      };
    },
  },

  watch: {
    organizationConfig: async function () {
      await this.loadMapData();
      await this.loadImage();
    },
    filteredSpot: {
      deep: true,
      handler: function () {
        const hotspotsData = cloneDeep(this.hotspots);
        hotspotsData.map((data) => {
          if (data.spotId == this.filteredSpot.id) {
            data.x = this.filteredSpot.map_position_x;
            data.y = this.filteredSpot.map_position_y;
          }
          return data;
        });
        this.hotspots = hotspotsData;
      },
    },
  },

  data() {
    return {
      imageSrc: "",
      imageWidth: 0,
      imageHeight: 0,
      zoomOptions: {
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        startX: 0,
        startY: 0,
        isDragging: false,
        hasDragged: false,
      },
      currentSpot: {
        x: 0,
        y: 0,
        isDragging: false,
        hasDragged: false,
      },
      spotDragShadow: {
        x: 0,
        y: 0,
      },
      spotHelper: {
        x: 0,
        y: 0,
      },
      mapData: {
        pointSize: 15,
        defaultZoom: 1,
      },
      hotspots: [],
      spots: {},
      loading: false,
      showMapForm: false,
      showHelper: false,
      showCodes: false,
      dragStartsAt: 0,
      selectedSpot: null,
    };
  },

  async mounted() {
    await this.getSpotsList();
    await this.loadMapData();
    await this.loadImage();
  },

  methods: {
    loadMapData() {
      const fetchedMapData =
        this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_DATA];
      const mapData = {
        pointSize: parseInt(fetchedMapData.pointSize || 15),
      };
      this.mapData = mapData;
    },

    fetchImage(url) {
      this.loading = true;
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const calculateDimensions = (width, height, maxDimension) => {
            let adjustedHeight = width;
            let adjustedWidth = height;
            if (width > height) {
              adjustedWidth = Math.ceil(width * (maxDimension / width));
              adjustedHeight = Math.ceil((adjustedWidth * height) / width);
            } else {
              adjustedHeight = Math.ceil(height * (maxDimension / height));
              adjustedWidth = Math.ceil((adjustedHeight * width) / height);
            }
            return { adjustedWidth, adjustedHeight };
          };

          const container = this.$refs.mapContainer;
          const containerWidth = container.offsetWidth;
          const containerHeight = container.offsetHeight;

          const { adjustedWidth, adjustedHeight } = calculateDimensions(
            img.width,
            img.height,
            1000
          );
          this.imageWidth = adjustedWidth;
          this.imageHeight = adjustedHeight;

          const defaultOffsetX =
            (containerWidth - adjustedWidth * this.zoomOptions.scale) / 2;
          const defaultOffsetY =
            (containerHeight - adjustedHeight * this.zoomOptions.scale) / 2;
          let scale =
            Math.min(containerWidth, containerHeight) /
            Math.min(adjustedWidth, adjustedHeight);

          const zoomOptions = cloneDeep(this.zoomOptions);
          zoomOptions.offsetX = defaultOffsetX;
          zoomOptions.offsetY = defaultOffsetY;
          zoomOptions.scale = scale;
          this.zoomOptions = zoomOptions;

          this.imageSrc = img.src;
          this.loading = false;
          resolve(img);
        };
        img.onerror = () => {
          reject(new Error("Could not load image at " + imageUrl));
        };
        img.src = url;
      });
    },

    async loadImage() {
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      await this.fetchImage(imageUrl);
      this.zoomOut();
    },

    async mapImageChanged(file_url) {
      this.loading = true;
      await this.$store.dispatch("organizationOptions/updateOptionByKey", {
        key: OPTIONS_SPOTS_MAP_IMAGE_URL,
        value: file_url,
        organizationId: this.organizationConfig.id,
      });
      this.loading = false;
    },

    async getSpotsList() {
      try {
        this.loading = true;
        let params = {
          page: {
            number: 1,
            size: 999999,
          },
          include: "spotType,category",
        };
        await this.$store.dispatch("spots/list", params);
        const spotsList = this.$store.getters["spots/list"];

        let foundSpot = false;
        const hotspots = spotsList.map((item) => {
          if (this.filteredSpot) {
            if (this.filteredSpot.id == item.id) {
              foundSpot = true;
            }
          }
          return {
            x: item.map_position_x,
            y: item.map_position_y,
            spotId: item.id,
            code: item.code,
          };
        });
        if (this.filteredSpot) {
          if (!foundSpot) {
            hotspots.push({
              x: this.filteredSpot.map_position_x,
              y: this.filteredSpot.map_position_y,
              code: this.filteredSpot.code,
              spotId: this.filteredSpot.id,
            });
          }
        }

        this.hotspots = hotspots;

        const spotsMapIdSpot = {};
        for (const item of spotsList) {
          spotsMapIdSpot[item.id.toString()] = item;
        }
        this.spots = spotsMapIdSpot;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async updateSpotMapPosition(spotId, x, y) {
      if (this.filteredSpot) {
        this.stopDraggingHotspot();
        this.stopDraggingImage();
        this.currentSpot = { x: 0, y: 0 };
        this.showMapForm = false;
        this.$emit("positionChanged", x, y);
        return;
      }
      if (!spotId) {
        return;
      }
      try {
        this.loading = true;
        const spotData = cloneDeep(this.spots[spotId]);
        spotData.map_position_x = x;
        spotData.map_position_y = y;
        await this.$store.dispatch("spots/update", spotData);
        await this.getSpotsList();
        this.closeMapForm();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteSpotPosition(spotId) {
      await this.updateSpotMapPosition(spotId, 0, 0);
      this.closeMapForm();
    },

    async updateMapData() {
      this.loading = true;
      const mapData = cloneDeep(this.mapData);
      await this.$store.dispatch("organizationOptions/updateOptionByKey", {
        key: OPTIONS_SPOTS_MAP_IMAGE_DATA,
        value: mapData,
        organizationId: this.organizationConfig.id,
      });
      this.loading = false;
    },

    getSpotCoords(event) {
      const { scale } = this.zoomOptions;
      const rect = this.$refs.imageContainer.getBoundingClientRect();
      const x = (event.clientX - rect.x) / scale;
      const y = (event.clientY - rect.y) / scale;
      return { x, y };
    },

    getHotspotStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = hotspot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${this.mapData.pointSize}px`,
          height: `${this.mapData.pointSize}px`,
        };
      }

      const markerWith = this.mapData.pointSize * scale;
      const markerHeight = this.mapData.pointSize * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale +
        offsetWidth / 2 +
        offsetX -
        markerWith / 2;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight / 2;

      return {
        position: "absolute",
        left: `${newLeft}px`,
        top: `${newTop}px`,
        width: `${markerWith}px`,
        height: `${markerHeight}px`,
      };
    },

    getMapFormStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = this.currentSpot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${this.mapData.pointSize}px`,
          height: `${this.mapData.pointSize}px`,
        };
      }

      const markerHeight = this.mapData.pointSize * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale + offsetWidth / 2 + offsetX - 150;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight -
        186 -
        5;

      return {
        left: `${newLeft}px`,
        top: `${newTop}px`,
      };
    },

    zoom(event) {
      event.preventDefault();
      const zoomSpeed = 0.1;
      const scaleFactor = event.deltaY > 0 ? -zoomSpeed : zoomSpeed;
      this.zoomOptions.scale = Math.min(
        Math.max(this.zoomOptions.scale + scaleFactor, 0.5),
        5
      );
    },

    zoomIn() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.min(this.zoomOptions.scale + zoomSpeed, 5);
    },

    zoomOut() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.max(
        this.zoomOptions.scale - zoomSpeed,
        0.5
      );
    },

    addHotspot(event) {
      this.stopDraggingHotspot();
      this.stopDraggingImage();
      if (!this.editMapMode) {
        return;
      }
      this.currentSpot = this.getSpotCoords(event);
      this.showMapForm = true;
    },

    openHotspotForm(hotspot) {
      this.currentSpot = hotspot;
      this.currentSpot.hasDragged = false;
      this.currentSpot.isDragging = false;
      this.showMapForm = true;
    },

    closeMapForm() {
      this.stopDraggingHotspot();
      this.stopDraggingImage();
      this.currentSpot = { x: 0, y: 0 };
      this.showMapForm = false;
    },

    onMouseDownImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.showMapForm) {
        if (this.$refs.mapFormContainer.contains(event.target)) {
          return;
        }
        this.closeMapForm();
        return;
      }
      if (this.$refs.zoomControlsContainer.contains(event.target)) {
        return;
      }

      this.dragStartsAt = Date.now();
      this.startDragImage(event);
    },

    onMouseMoveImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.zoomOptions.isDragging) {
        this.dragImage(event);
        return;
      }
      if (this.currentSpot.isDragging) {
        this.dragHotspot(event);
        return;
      }
      if (this.showHelper) {
        this.moveSpotHelper(event);
        return;
      }
    },

    onMouseUpImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.showMapForm) {
        if (this.$refs.mapFormContainer.contains(event.target)) {
          return;
        }
      }
      if (this.$refs.zoomControlsContainer.contains(event.target)) {
        return;
      }

      if (Date.now() - this.dragStartsAt < 100) {
        if (this.currentSpot.isDragging) {
          this.openHotspotForm(this.currentSpot);
        } else {
          this.addHotspot(event);
        }
        this.zoomOptions.isDragging = false;
        this.currentSpot.isDragging = false;
        return;
      }
      if (this.zoomOptions.hasDragged) {
        this.zoomOptions.isDragging = false;
        this.stopDraggingImage(event);
        return;
      }
      if (this.currentSpot.hasDragged) {
        this.currentSpot.isDragging = false;
        this.stopDraggingHotspot(event);
        this.updateSpotMapPosition(
          this.currentSpot.spotId,
          this.spotDragShadow.x,
          this.spotDragShadow.y
        );
        return;
      }
    },

    onMouseLeaveImage(event) {
      event.preventDefault();
      event.stopPropagation();

      this.stopDraggingImage();
      this.stopDraggingHotspot();
    },

    onMouseDownHotspot(event, hotspot) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.editMapMode) {
        this.openHotspotForm(hotspot);
        return;
      }
      if (this.filteredSpot) {
        if (hotspot.spotId != this.filteredSpot.id) {
          this.openHotspotForm(hotspot);
          return;
        }
      }
      this.dragStartsAt = Date.now();
      this.startDraggingHotspot(event, hotspot);
    },

    startDragImage(event) {
      this.zoomOptions.isDragging = true;
      this.zoomOptions.startX = event.clientX - this.zoomOptions.offsetX;
      this.zoomOptions.startY = event.clientY - this.zoomOptions.offsetY;
    },

    dragImage(event) {
      this.zoomOptions.hasDragged = true;
      this.zoomOptions.offsetX = event.clientX - this.zoomOptions.startX;
      this.zoomOptions.offsetY = event.clientY - this.zoomOptions.startY;
      return;
    },

    stopDraggingImage(event) {
      this.zoomOptions.isDragging = false;
      this.zoomOptions.hasDragged = false;
      return;
    },

    startDraggingHotspot(event, hotspot) {
      this.currentSpot = hotspot;
      this.currentSpot.isDragging = true;
    },

    dragHotspot(event) {
      this.currentSpot.hasDragged = true;
      this.spotDragShadow = this.getSpotCoords(event);
    },

    stopDraggingHotspot(event) {
      this.currentSpot.isDragging = false;
      this.currentSpot.hasDragged = false;
    },

    moveSpotHelper(event) {
      this.spotHelper = this.getSpotCoords(event);
    },

    setFilteredSpotPosition() {
      this.updateSpotMapPosition(
        this.filteredSpot.id,
        this.currentSpot.x,
        this.currentSpot.y
      );
    },

    viewSpot(spot) {
      this.$router.push({ name: "ListSpot" });
      this.$router.push(this.$objectViewRoute(spot));
    },
  },
};
</script>

<style lang="scss">
.container-fluid.loading {
  cursor: progress;
}

.image-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 12px;
  background-color: #aec082;

  .map-form {
    width: 300px;
    min-height: 160px;
    background-color: #fff;
    filter: drop-shadow(0px 25px 50px #00000040);
    border-radius: 9px;
    position: absolute;
    z-index: 20;
    padding: 8px;
    cursor: auto;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -12px;
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 14px solid #fff;
    }
    &-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      position: relative;
      padding: 0 25px;
      p {
        color: #ef8152;
        position: relative;
        padding: 0 0 0 20px;
        font-family: "Greycliff CF Bold", sans-serif;
        margin: 0;
        &:before {
          content: "";
          width: 14px;
          height: 19px;
          background: url("/img/e-pin.svg") no-repeat center center;
          background-size: 14px 19px;
          display: block;
          position: absolute;
          top: 4px;
          left: 0;
        }
      }
      button {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        i {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    &-content {
      width: 100%;
      margin: 12px 0 0 0;
      display: flex;
      flex-direction: column;
      .el-select {
        width: 100%;
        .el-input {
          input {
            &.el-input__inner {
              min-height: 37px;
            }
          }
        }
      }
      .location {
        width: 100%;
        margin: 7px 0 0 0;
        display: flex;
        flex-direction: column;
        span {
          font-size: 13px;
          line-height: 16px;
          font-family: "Greycliff CF Bold", sans-serif;
        }
        &-selected {
          width: 100%;
          min-height: 55px;
          margin: 5px 0 0 0;
          border-radius: 6px;
          background-color: #aec0824d;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          .located {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 35px 10px 10px;
            position: relative;
            &-image {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              border: solid 1px #aec082;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            &-text {
              width: calc(100% - 35px);
              display: flex;
              flex-direction: column;
              padding: 0 0 0 10px;
              span {
                font-size: 12px;
                line-height: 15px;
                margin: 0 0 2px 0;
                color: #374151;
                font-family: "Greycliff CF Medium", sans-serif;
                &:first-child {
                  font-family: "Greycliff CF Bold", sans-serif;
                }
                &:last-of-type {
                  margin: 0;
                }
              }
            }
            &-delete {
              width: 35px;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: center;
              position: absolute;
              top: 0;
              right: 0;
              button {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #c81e1e;
                border: none;
                border-radius: 0 6px 6px 0;
                img {
                  widows: 16px;
                  height: 16px;
                }
              }
            }
            &-view {
              width: 35px;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: center;
              position: absolute;
              top: 0;
              right: 0;
              button {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #ef8152;
                border: none;
                border-radius: 0 6px 6px 0;
                img {
                  widows: 16px;
                  height: 16px;
                }
              }
            }
          }
          p {
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-family: "Greycliff CF Medium", sans-serif;
          }
          button {
            margin: 0;
            font-family: "Greycliff CF Medium", sans-serif;
            width: 100%;
            min-height: 55px;
            border-radius: 6px;
            background-color: #ef8152;
            color: #fff;
          }
        }
      }
    }
  }
}

.image-container.dragging {
  cursor: move;
}

.image-container.draggingSpot {
  cursor: grabbing;
}

.image-container.loading {
  cursor: progress;
}

img {
  width: auto;
  height: auto;
  max-width: none;
  max-height: none;
  user-select: none;
}

.zoom-controls {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 20px #00000029;
  button {
    background-color: #fff;
    //border: 1px solid #ccc;
    border: none;
    border-radius: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px rgba(145, 145, 145, 0.3);
    &:hover {
      background-color: #eee;
    }
    &:first-of-type {
      border-radius: 6px 6px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 6px 6px;
      border-bottom: none;
    }
    i {
      font-size: 16px;
      color: #576654;
      font-weight: 500;
    }
  }
}

.hotspot {
  position: absolute;
  background-color: #c1d0a1;
  border: solid 2px #7e8d6d;
  border-radius: 50%;
  cursor: pointer;
  //z-index: 10;
  transition: transform 0.1s;

  &:hover {
    .hotspot-code {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }

  &.searched {
    background-color: #ef8152;
    border: solid 2px #7e8d6d;
  }

  &-current {
    position: absolute;
    background-color: #0c8ce9;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    transition: transform 0.1s;
  }

  &-shadow {
    position: absolute;
    background-color: #c1d0a1;
    border: solid 3px #0c8ce9;
    border-radius: 50%;
    z-index: 10;
    transition: transform 0.1s;
  }

  &-helper {
    position: absolute;
    background-color: #0c8ce9;
    border: solid 3px #0c8ce9;
    border-radius: 50%;
    z-index: 10;
    transition: transform 0.1s;
  }

  &-marker {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &-code {
    position: absolute;
    right: calc(100% + 2px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
    background: #ef8152 0% 0% no-repeat padding-box;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.4));
    border-radius: 10px;
    padding: 8px 13px;
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    font-family: "Greycliff CF Bold", sans-serif;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:after {
      content: "";
      position: absolute;
      right: -18px;
      transform: translateY(-50%);
      top: 50%;
      width: 0;
      height: 0;
      border-left: 10px solid #ef8152;
      border-right: 10px solid transparent;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }
  }
}

li.el-select-dropdown__item.spot-used,
li.el-select-dropdown__item.spot-used:hover {
  background-color: #aec0824d;
}
</style>
